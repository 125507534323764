import aside from "./aside";
import errors from "./errors";
import global from "./global";
import login from "./login";
import dashboard from "./Dashboard/dashboard.json";

//User Management
import user from "./UserManagement/users.json";
import property_owners from "./UserManagement/propertyOwners.json";

// Locations
import allLocations from "./Locations/allLocations.json";
import site from "./Locations/site.json";
import properties from "./Locations/properties.json";
import units from "./Locations/units.json";

// Vendors and Teams
import vendors from "./VendorTeams/vendors.json";
import teams from "./VendorTeams/teams.json";

export default {
  ...global,
  ...errors,
  ...aside,
  ...login,
  ...dashboard,
  ...allLocations,
  ...site,
  ...properties,
  ...units,
  ...user,
  ...property_owners,
  ...vendors,
  ...teams,
};
